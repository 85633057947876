import axios from 'axios'
import {OrganizationModel, WebpagePagesModel, WebsiteByOrg, WebsiteModel} from '../models/OrganizationModel'
import {API_URL} from './constants'

export const WEBSITES_BY_ORG_URL = `${API_URL}/websites-by-org`;
export const WEBSITE_URL = `${API_URL}/websites/`
export const PATCH_WEBSITE_PAGE_URL = `${API_URL}/patch-website-pages/`
export const DELETE_WEBSITE_URL = `${API_URL}/delete_websites/`
export const PATCH_WEBSITE_URL = `${API_URL}/patch_websites/`
export const ORGANIZATION_URL = `${API_URL}/organizations/`
export const DEPARTMENT_URL = `${API_URL}/departments/`
export const BULK_CREATE_ORGANIZATION_URL = `${API_URL}/onboard-organization/`
export const PATCH_ORGANIZATION_URL = `${API_URL}/patch_organizations/`
export const PATCH_DEPARTMENT_URL = `${API_URL}/patch_departments/`
export const DELETE_ORGANIZATION_URL = `${API_URL}/delete_organizations/`
export const DELETE_DEPARTMENT_URL = `${API_URL}/delete_departments/`
export const PUBLIC_STATS_URL = `${API_URL}/public-stats`

export function createOrganization(name: string) {
  return axios.post(ORGANIZATION_URL, {name});
}

export function createDepartment(name: string) {
  return axios.post(DEPARTMENT_URL, {name});
}

export function viewOrganizationById(id: any) {
  return axios.get<OrganizationModel>(ORGANIZATION_URL + id, {});
}

export function viewOrganization(filter?: any) {
  return axios.get<Array<OrganizationModel>>(ORGANIZATION_URL, {params: {filter}});
}

export function viewDepartment(filter?: any) {
  return axios.get<Array<OrganizationModel>>(DEPARTMENT_URL, {params: {filter}});
}

export function bulkCreateOrganization(file: any) {
  const config = {
      headers: {
          'content-type': 'multipart/form-data'
      }
  };
  const formData = new FormData();
  formData.append('file', file)
  return axios.post(BULK_CREATE_ORGANIZATION_URL, formData, config);
}

export function editOrganization(id: number, name: string) {
  return axios.post(PATCH_ORGANIZATION_URL + id, {name});
}

export function editDepartment(id: number, name: string, emailConfigs?: any[]) {
  return axios.post(PATCH_DEPARTMENT_URL + id, {name, emailConfigs});
}

export function getDepartment(id: number) {
  return axios.get(DEPARTMENT_URL + id);
}

export function getOrganization(id: number) {
  return axios.get(ORGANIZATION_URL + id);
}

export function deleteOrganization(id: number) {
  return axios.post(DELETE_ORGANIZATION_URL + id);
}

export function deleteDepartment(id: number) {
  return axios.post(DELETE_DEPARTMENT_URL + id);
}

export function addWebsite(name: string, url: string, departmentId: number, userId?: string) {
  return axios.post(WEBSITE_URL, {name, url, departmentId, userId});
}

export function getWebsitesByOrg(filter: any, includeTestScheduleCount?: boolean) {
  return axios.get<Array<WebsiteByOrg>>(WEBSITES_BY_ORG_URL, {params: {filter, includeTestScheduleCount}});
}

export function viewWebsite(organizationId?: number, noInclude?: boolean) {
  const params: any = {
    filter: {
      include: noInclude ? [] : [
         "organization",
         "user"
      ]
    }
  };
  if (organizationId != null && organizationId != -1) {
    params.filter.where = {organizationId};
  }
  return axios.get<Array<WebsiteModel>>(WEBSITE_URL, {params});
}

export function viewWebpages(websiteId: number, where?: any) {
  return axios.get<WebpagePagesModel>(WEBSITE_URL + `${websiteId}/webpages`, {params: {filter: {where}}});
}

export function setWebsiteStatus(id: number, status: string) {
  return axios.post(PATCH_WEBSITE_PAGE_URL + id, {id, status});
}

export function editWebsite(id: number, name: string, url: string, departmentId: number, userId?: string) {
  return axios.post(PATCH_WEBSITE_URL + id, {name, url, departmentId, userId});
}

export function getWebsite(id: number) {
  const params: any = {
    filter: {
      include: [
         "organization",
         "user"
      ]
    }
  };
  return axios.get(WEBSITE_URL + id, {params});
}

export function deleteWebsite(id: number) {
  return axios.post(DELETE_WEBSITE_URL + id);
}

export function getPublicStats() {
  return axios.get(PUBLIC_STATS_URL);
}
