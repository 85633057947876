import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../../setup';
import Pagination from '../layout/Pagination';
import { getActivityLogs } from '../../modules/auth/redux/TestRunCRUD';
import { ActivityLogs } from '../../modules/auth/models/TestRunModel';
import Markdown from 'react-markdown';
import moment from 'moment';

const ViewActivityLogs: React.FC = () => {
    const { pathname } = useLocation();
    const [nextParams, setNextParams] = useState<any>();
    const [prevParams, setPrevParams] = useState<any>();
    const [pageNo, setPageNo] = useState<number>(1);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<Array<ActivityLogs>>();
    const [dataError, setDataError] = useState<{ error: string }>();
    const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile, shallowEqual)
    const limit = 10;

    const fetchData = (direction?: 'next' | 'prev') => {
        let filter = {limit: limit + 1};
        if (direction == 'next') {
            setPageNo(p => p + 1);
            filter = nextParams || filter;
        } else if (direction == 'prev') {
            setPageNo(p => p - 1);
            filter = prevParams || filter;
        } else {
            setPageNo(1);
            filter = {limit: limit + 1};
        }
        setIsLoading(true);
        getActivityLogs(filter).then((result) => {
            setData(result.data);
            if (result.data.length == 0) {
                return;
            }
            const itemIndex = result.data.length > limit ? result.data.length - 2 : result.data.length - 1;
            setPrevParams({ direction: nextParams ? 'prev': undefined, lastCreated: nextParams?.created, lastId: nextParams?.lastId, limit: limit + 1 });
            setNextParams({ direction: 'next', lastCreated: result.data[itemIndex].created, lastId: result.data[itemIndex].id, limit: limit + 1 });
        }).catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }))
        .finally(() => setIsLoading(false));
    }

    useEffect(fetchData, []);

    if (isLoading) {
        return (
            <div>
                <br />
                <strong>
                    <span>Loading... Please wait... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </strong>
            </div>
        );
    }

    if (dataError || data == null) {
        return (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    {dataError ? (
                        <div>
                            <br />
                            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                                <div className='alert-text font-weight-bold'>{dataError.error}</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="bredcrumb-cnntr">
                <ul>
                    <li><Link to={`/v2/dashboard`}>Home</Link></li>
                    <li>View Logs</li>
                </ul>
            </div>
            <div className="site-head">
                <h1 className="main-title">View Logs</h1>
            </div>
            <div className="project-list">
                <table className="weblist-table add-manage-test">
                    <thead>
                        <tr>
                            <th scope="col">Activity</th>
                            <th scope="col">Priority</th>
                            <th scope="col">Timestamp</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 ? null : 'No data to display'}
                        {data?.slice(0, limit).map(row => {
                            const created = moment(row.created).tz('Asia/Kolkata');

                            return (
                                <tr>
                                    <td className="website-name add-test">
                                        <Markdown children={row.content} />
                                    </td>
                                    <td><strong>{row.priority}</strong></td>
                                    <td><strong>{created.format('DD MMM YYYY')}</strong><br />{created.format('h:mm:ss A')}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <nav aria-label="Page navigation" className="pagination-wrap">
                {data ?
                    <Pagination
                        currentPage={pageNo}
                        totalCount={data.length}
                        pageSize={limit}
                        infinite={true}
                        onNext={() => fetchData('next')}
                        onPrevious={() => fetchData('prev')}
                    /> : null}
            </nav>
        </>
    )
}

export { ViewActivityLogs }
