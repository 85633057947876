import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../../setup';
import Pagination from '../layout/Pagination';
import _ from 'lodash';
import { deleteUser, getUserByOrg } from '../../modules/auth/redux/AuthCRUD';
import { UserDbModel, UserModel } from '../../modules/auth/models/UserModel';
import { getUserLabel } from '../../modules/utils';
import { AddEditUser } from './AddEditUser';

const ManageUsers: React.FC = () => {
    const { pathname } = useLocation();
    const history = useHistory();
    const { action, role, orgId, userId }: any = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<Array<UserDbModel>>();
    const [filteredData, setFilteredData] = useState<Array<UserDbModel>>();
    const [dataError, setDataError] = useState<{ error: string }>();
    const [search, setSearch] = useState<string>('');
    const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile, shallowEqual);
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
    const roleTitle = getUserLabel(role);
    const pageSize = 10;
    let pgNo = 1;
    if (action == 'manage') {
        pgNo = parseInt(userId);
    }
    if (pgNo < 1 || Number.isNaN(pgNo)) {
        pgNo = 1
    }

    const start = (pgNo - 1) * pageSize;

    const fetchData = () => {
        setIsLoading(true);
        getUserByOrg(orgId, role).then((result) => setData(result.data))
            .catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }))
            .finally(() => setIsLoading(false));
    }

    useEffect(fetchData, [role, orgId]);

    useEffect(() => {
        const newFilteredData = data?.filter(x => x.email.toLowerCase().includes(search.toLowerCase()) || x.parichay?.fullName?.toLowerCase().includes(search.toLowerCase()));
        setFilteredData(newFilteredData);
    }, [search, data]);

    if (isLoading) {
        return (
            <div>
                <br />
                <strong>
                    <span>Loading... Please wait... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </strong>
            </div>
        );
    }

    if (dataError || data == null) {
        return (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    {dataError ? (
                        <div>
                            <br />
                            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                                <div className='alert-text font-weight-bold'>{dataError.error}</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    const onDelete = (id: string) => {
        if (!window.confirm('Are you sure want to delete?')) {
            return;
        }
        deleteUser(id).then(() => {
            setData(data?.filter(x => x.id != id));
        });
    }

    const manageUrlPrefix = `/v2/core${orgId ? `/org/${orgId}` : ''}`;
    return (
        <>
            <div className="bredcrumb-cnntr">
                <ul>
                    <li><Link to={`/v2/dashboard`}>Home</Link></li>
                    {orgId ? <li><Link to={`/v2/core/org/${orgId}`}>
                        Manage Organization
                    </Link></li> : null}
                    <li>Manage {roleTitle}</li>
                </ul>
            </div>
            <div className="site-head">
                <h1 className="main-title">Manage {roleTitle}</h1>
                <div className="site-head-right">
                    <div className="search-web-form">
                        <span className="icon"><i className="fa fa-search" aria-hidden="true" /></span>
                        <input type="search" id="search" placeholder="Search by email or name" value={search} onChange={(e) => setSearch(e.target.value)} />
                        {search?.trim().length > 0 ? <span className="searchBox-clear js-clearSearchBox" onClick={() => setSearch('')}>clear search</span> : null}
                    </div>
                    <Link to={`${manageUrlPrefix}/users/${role}/add`} className="btn btn-red" data-target="#details-popup">
                        <em className="fa fa-plus" aria-hidden="true"></em> Add {roleTitle}
                    </Link>
                </div>
            </div>
            <div className="project-list">
                <table className="weblist-table add-manage-test">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Designation</th>
                            <th scope="col">Departments</th>
                            <th scope="col">Mobile No</th>
                            <th scope="col" className="action">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData && filteredData.length > 0 ? null : 'No data to display'}
                        {filteredData?.slice(start, start + pageSize).map(row => (
                            <tr>
                                <td className="website-name add-test">
                                    {row.parichay?.fullName} <br />
                                </td>
                                <td>{row.email}</td>
                                <td>{row.parichay?.designation}</td>
                                <td>{row.departments?.map(d => d.name).join(', ')}</td>
                                <td>{row.parichay?.mobileNo}</td>
                                {user.email == row.email ? <td /> : 
                                <td>
                                    <Link className='edit' data-target="#details-popup"
                                        to={`${manageUrlPrefix}/users/${role}/edit/${row.id}`}>
                                        <em className="fa fa-pencil" aria-hidden="true"></em>
                                    </Link>
                                    <Link className='edit'
                                        to={`#`}
                                        onClick={() => {
                                            onDelete(row.id);
                                        }}>
                                        <em className="fa fa-trash-o" aria-hidden="true"></em>
                                    </Link>
                                </td>}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <nav aria-label="Page navigation" className="pagination-wrap">
                {data ?
                    <Pagination
                        currentPage={pgNo}
                        totalCount={filteredData?.length}
                        pageSize={pageSize}
                        infinite={false}
                        onPageChange={(page: number) => history.push(`${manageUrlPrefix}/users/${role}/manage/${page}`)}
                    /> : null}
            </nav>
            <AddEditUser onSave={fetchData} manageUrlPrefix={manageUrlPrefix} />
        </>
    )
}

export { ManageUsers }
