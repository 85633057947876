import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../../setup';
import { WebsiteByOrg } from '../../modules/auth/models/OrganizationModel';
import { deleteWebsite, getWebsitesByOrg } from '../../modules/auth/redux/OrganizationCRUD';
import { AddEditWebsite } from './AddEditWebsite';
import { DeleteIconLink, EditIconLink } from '../layout/MasterLayout';
import { UserModel } from '../../modules/auth/models/UserModel';
import { WebsiteModel } from '../../modules/auth/models/OrganizationModel';
import { WebsiteAccordion } from './WebsiteAccordion';

const ManageWebsite: React.FC = () => {
    const { pathname } = useLocation();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<Array<WebsiteByOrg>>();
    const [dataError, setDataError] = useState<{ error: string }>();
    const [search, setSearch] = useState<string>('');
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const isTester = user.gamyata?.role == "TESTER";
    const fetchData = () => {
        setIsLoading(true);
        getWebsitesByOrg({}).then((r) => setData(r.data))
            .catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }))
            .finally(() => setIsLoading(false));
    };
    useEffect(fetchData, []);

    if (isLoading) {
        return (
            <div>
                <br />
                <strong>
                    <span>Loading... Please wait... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </strong>
            </div>
        );
    }

    if (dataError || data == null) {
        return (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    {dataError ? (
                        <div>
                            <br />
                            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                                <div className='alert-text font-weight-bold'>{dataError.error}</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    const onDeleteWebsite = (id: number) => {
        if (!window.confirm('Are you sure want to delete?')) {
            return;
        }
        deleteWebsite(id).then(() => {
            setData(data.map(x => {
                if (!x.websites) {
                    return x;
                }
                x.websites = x.websites.filter(x => x.id != id);
                return x;
            }));
        });
    }
    return (
        <>
            <div className="bredcrumb-cnntr">
                <ul>
                    <li><Link to={`/v2/dashboard`}>Home</Link></li>
                    <li>Manage Websites</li>
                </ul>
            </div>
            <div className="site-head">
                <h1 className="main-title">Manage Websites</h1>
                <div className="site-head-right">
                    <div className="search-web-form">
                        <span className="icon"><i className="fa fa-search" aria-hidden="true" /></span>
                        <input type="search" id="search" placeholder="Search Website/Orgnization" value={search} onChange={(e) => setSearch(e.target.value)} />
                        {search?.trim().length > 0 ? <span className="searchBox-clear js-clearSearchBox" onClick={() => setSearch('')}>clear search</span> : null}
                    </div>
                    {isTester ? null :
                        <Link to={'/v2/core/website/manage/add'} className="btn btn-red" data-target="#details-popup"><em className="fa fa-plus" aria-hidden="true"></em> Add Sites</Link>
                    }
                </div>

            </div>
            <div className="project-list">
                <WebsiteAccordion search={search} data={data} renderActions={
                    (website: WebsiteModel) =>
                        <>
                            <Link to={`/v2/core/website/manage/webpages/${website.id}`} className="btn">Manage Webpages</Link>
                            {isTester ? null :
                                <>
                                    <EditIconLink href='#' className='edit' title="Edit Website" onClick={e => { history.push(`/v2/core/website/manage/edit/${website.id}`);e.preventDefault();}} data-target="#details-popup" />
                                    <DeleteIconLink href='#' title='Delete Website' className='edit' onClick={e => { onDeleteWebsite(website.id);e.preventDefault();}} data-target="#details-popup" />
                                </>}
                        </>
                } />
            </div>
            <AddEditWebsite onSave={fetchData} />
        </>
    )
}

export { ManageWebsite }
