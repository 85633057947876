import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../../setup';
import { decodeURISafe } from '../../modules/utils';
import { getTestRunsWithFilter, getTestScheduleReport } from '../../modules/auth/redux/TestRunCRUD';
import { TestRunModel, TestScheduleReport } from '../../modules/auth/models/TestRunModel';
import _ from 'lodash';
import cronstrue from 'cronstrue';
import Pagination from '../layout/Pagination';
import Highcharts from 'highcharts';
import { defaultLineHighChartOptions, defaultLineHighChartOptionsSeries, getFrequencyText, getScoreHex } from '../utils';
import { BreadcrumbData, Breadcrumbs, SortedTh } from '../layout/MasterLayout';
import HighchartsReact from 'highcharts-react-official';

const getScoreColor = (score: number) => {
    if (score > 89) {
        return 'green';
    } else if (score > 39) {
        return 'orange';
    }
    return 'red';
}

const ViewTestSchedule: React.FC = () => {
    const { testScheduleId, pageNo = 1 }: any = useParams();
    const { pathname } = useLocation();
    const history = useHistory();
    const [data, setData] = useState<TestScheduleReport>();
    const [testRuns, setTestRuns] = useState<TestRunModel[]>();
    const [isTestRunsLoading, setIsTestRunsLoading] = useState(false);
    const [testRunsError, setTestRunsError] = useState<{ error: string }>();
    const [isLoading, setIsLoading] = useState(false);
    const [dataError, setDataError] = useState<{ error: string }>();
    const [showFrequencyInfo, setShowFrequencyInfo] = useState<boolean>(false);
    const [sortColumn, setSortColumn] = useState<string | undefined>('created');
    const [sortDirection, setSortDirection] = useState<string | undefined>('desc');
    const limit = 10;
    let pgNo = parseInt(pageNo);
    if (pgNo < 1 || Number.isNaN(pgNo)) {
        pgNo = 1
    }

    const fetchTestRuns = () => {
        setIsTestRunsLoading(true);
        const filter: any = { limit: limit + 1, skip: (pgNo - 1) * limit };
        if (sortDirection) {
            filter.order = `${sortColumn} ${sortDirection.toUpperCase()}`;
        }
        getTestRunsWithFilter({ ...filter, where: { testScheduleId }, include: ['user', 'apiKey'] }).then((result) => {
            setTestRuns(result.data);
            setTestRunsError(undefined);
        }).catch(e => setTestRunsError({ error: "Server Error! Cannot fetch test runs!" }))
            .finally(() => setIsTestRunsLoading(false));
    }

    const fetchData = () => {
        setIsLoading(true);
        getTestScheduleReport(testScheduleId).then((result) => {
            setData(result.data);
            setDataError(undefined);
        }).catch(e => setDataError({ error: "Server Error! Cannot fetch test schedule report!" }))
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        fetchData();
    }, [testScheduleId]);

    useEffect(() => {
        fetchTestRuns();
    }, [testScheduleId, pgNo, sortDirection]);

    if (isLoading) {
        return (
            <div>
                <br />
                <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </div>
        );
    }

    if (dataError || data == null) {
        return (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    {dataError ? (
                        <div>
                            <br />
                            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                                <div className='alert-text font-weight-bold'>{dataError.error}</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
    const totalTestRuns = data.counts.completeCnt + data.counts.failedCnt + data.counts.partiallyCompleteCnt;

    let defaultBreadcrumbs: BreadcrumbData[];
    //@ts-ignore
    if (history.location.state && history.location.state.previousBreadcrumbs) {
        //@ts-ignore
        defaultBreadcrumbs = history.location.state.previousBreadcrumbs.concat([{ text: 'View Test Runs' }]);
    }
    else {
        defaultBreadcrumbs = [
            {
                href: `/v2/activities/testmgmt/schedules`,
                text: 'Manage Test'
            },
            {
                text: 'View Test Runs'
            }
        ];
    }

    return (
        <div className='test-runsfor'>
            <Breadcrumbs data={defaultBreadcrumbs} />
            <div className="site-head">
                <h1 className="main-title">Test Runs For</h1>
            </div>

            <div className="site-details-widgt">
                <div className="site-details">
                    <a className="h2 underline" href={data.testSchedule.website.url}
                        title="external site that opens in a new window"
                        target="_blank"><strong>{decodeURISafe(data.testSchedule.website.url)}</strong></a><br />
                </div>
                <ul className="site-info">
                    <li><strong className="h2">{totalTestRuns}</strong> Total Test Done</li>
                    <li><strong className="h2 green">{data.counts.completeCnt}</strong> Complete</li>
                    <li><strong className="h2 orange">{data.counts.partiallyCompleteCnt}</strong> Partially Complete</li>
                    <li><strong className="h2 red">{data.counts.failedCnt}</strong> Failure</li>
                </ul>
            </div>
            <div className="historical-db-control">
                <div className="filter-by">
                    <div className="row">

                        <div className="col-md-12 mb-15">
                            <div className="trends-graph-box text-center testrunsfor-details">
                                <ul>
                                    <li><span>Test Name :</span> <strong>{data.testSchedule.name}</strong></li>
                                    <li><span>Test Mode :</span> <strong>{_.startCase(data.testSchedule.testSettings.testMode)}</strong></li>
                                    <li><span>WCAG Level :</span> <strong>{data.testSchedule.testSettings.wcagLevel}</strong></li>
                                    <li><span>WCAG Version :</span> <strong>{data.testSchedule.testSettings.wcagVersion}</strong></li>
                                    <li><span>Frequency :</span> <strong>{getFrequencyText(data.testSchedule.cron)} </strong>
                                        {data.testSchedule.cron ? <div className="view-note">
                                            <a href="#" onClick={() => setShowFrequencyInfo(true)} className="show-error-note red" title="Information"><em className="fa fa-exclamation-circle" aria-hidden="true"></em></a>
                                            <div className={`error-notbox ${showFrequencyInfo ? 'show' : ''}`}>
                                                <p>Tests will be conducted
                                                    <strong> {cronstrue.toString(data.testSchedule.cron).toLowerCase()}</strong>
                                                </p>
                                                <a href="#" onClick={() => setShowFrequencyInfo(false)} className="alert-close"><em className="fa fa-times" aria-hidden="true"></em></a>
                                            </div>
                                        </div> : null}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="trends-graph-box">
                                <div className="graph-title text-right">
                                    <h3 className="h3">Score Trends</h3>
                                    <p>Score trend over the period time</p>
                                </div>
                                {data.trends && data.trends.length > 0 ? <>
                                    <div className="graph-title-inner">
                                        Latest Score: <span className="score-circle score text-center">{data.trends[0].score}</span>
                                    </div>
                                    <div id="score_trends">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={{
                                                ...defaultLineHighChartOptions,
                                                series: [Object.assign({}, defaultLineHighChartOptionsSeries, {
                                                    name: 'Score',
                                                    type: 'line',
                                                    data: data.trends.map(x => ({ y: x.score, name: moment(x.created).tz('Asia/Kolkata').format('MMMM Do YYYY') })),
                                                    colors: data.trends.map(x => getScoreHex(x.score)),
                                                })],
                                            }}
                                        />
                                    </div>
                                </> : 'No data found'}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="trends-graph-box">
                                <div className="graph-title text-right">
                                    <h3 className="h3">Issue Trends</h3>
                                    <p>Issue trend over the period time</p>
                                </div>
                                {data.trends && data.trends.length > 0 ? <>
                                    <div className="graph-title-inner">
                                        Latest Issue: <span className="score-circle issue text-center">{data.trends[0].cntTotalIssues}</span>
                                    </div>
                                    <div id="issue_trends">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={{
                                                ...defaultLineHighChartOptions,
                                                series: [Object.assign({}, defaultLineHighChartOptionsSeries, {
                                                    name: 'Issues',
                                                    type: 'line',
                                                    data: data.trends.map(x => ({ y: x.cntTotalIssues, name: moment(x.created).tz('Asia/Kolkata').format('MMMM Do YYYY') })),
                                                    colors: ['#49a0f0', ...Array(data.trends.length).fill('#000')],
                                                })],
                                            }}
                                        />
                                    </div>
                                </> : 'No data found'}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="project-list">
                {isTestRunsLoading ? (
                    <div>
                        <br />
                        <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </div>
                ) : (
                    <>
                        <table className="weblist-table dataTable">
                            <thead>
                                <tr>
                                    <SortedTh fieldName={'created'}
                                        sortColumn={sortColumn}
                                        setSortColumn={setSortColumn}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}>Run Date/Time</SortedTh>
                                    <SortedTh fieldName={'status'}
                                        sortColumn={sortColumn}
                                        setSortColumn={setSortColumn}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}>Status</SortedTh>
                                    <SortedTh fieldName={'score'}
                                        sortColumn={sortColumn}
                                        setSortColumn={setSortColumn}
                                        sortDirection={sortDirection}
                                        setSortDirection={setSortDirection}>Latest Score</SortedTh>
                                    <th scope="col">Initiated By</th>
                                    <th scope="col" className="action">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {testRuns?.slice(0, limit).map(run => {
                                    const created = run.created ? moment(run.created).tz('Asia/Kolkata') : null;
                                    return (
                                        <tr>
                                            <td>{created ? <><strong>{created.format('DD MMM YYYY')}</strong><br />{created.format('h:mm:ss A')}</> : null}</td>
                                            <td>
                                                {run.status == 'FAILURE' ? <img src="/assetsv2/images/Failure.svg" alt="Delete" className="del-icon" /> : null}
                                                {run.status == 'RUNNING' ? <img src="/assetsv2/images/running.svg" alt="Delete" className="del-icon" /> : null}
                                                {run.status == 'PENDING' ? <img src="/assetsv2/images/pending.svg" alt="Delete" className="del-icon" /> : null}
                                                {run.status == 'COMPLETE' ? <img src="/assetsv2/images/Success.svg" alt="Success" /> : null}
                                                {run.status == 'PARTIALLY COMPLETE' ? <img src="/assetsv2/images/Success.svg" alt="Success" /> : null}
                                                {/* TODO: other run status icons*/}
                                                <span> {run.status}</span>
                                            </td>
                                            <td>{run.score ? <strong className={getScoreColor(run.score)}>{run.score}</strong> : null}</td>
                                            <td>
                                                {run.apiKey ? <span>{run.apiKey.serviceName}</span> : null}
                                                {run.user ? <span>{run.user.parichay?.fullName || run.user?.email}</span> : null}
                                            </td>
                                            <td className="view-box">
                                                <a href='#' onClick={e => {
                                                    history.push(`/v2/core/test/${run.id}/overview`,
                                                        { previousBreadcrumbs: defaultBreadcrumbs.map((x, i) => i == defaultBreadcrumbs.length - 1 ? ({ ...x, href: history.location.pathname }) : x) });
                                                        e.preventDefault();
                                                }}
                                                    className="btn">View Test Report</a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
            {testRuns?.length == 0 ? <div style={{ textAlign: 'center', marginTop: 5 }}><strong>No data to display</strong></div> : null}
            <nav aria-label="Page navigation" className="pagination-wrap">
                {testRuns ?
                    <Pagination
                        currentPage={pgNo}
                        totalCount={totalTestRuns}
                        pageSize={limit}
                        infinite={false}
                        onPageChange={(page: number) => history.push(`/v2/activities/testmgmt/schedulereport/${testScheduleId}/${page}`)}
                    /> : null}
            </nav>
        </div>
    )
}

export { ViewTestSchedule }
