import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../../setup';
import Pagination from '../layout/Pagination';
import cronstrue from 'cronstrue';
import { deleteTestSchedule, getTestSchedulesWithSearch, runTest } from '../../modules/auth/redux/TestRunCRUD';
import { TestScheduleModel } from '../../modules/auth/models/TestRunModel';
import { AddEditTestSchedule } from './AddEditTestSchedule';
import { STANDARD_TEST_NAME, cleanString, getFrequencyText } from '../utils';
import { Breadcrumbs, BreadcrumbData, DeleteIconLink, DeleteDropdown, EditIconLink, ViewIconLink, RunIconLink } from '../layout/MasterLayout';

const ManageTestSchedules: React.FC = () => {
    const { pathname } = useLocation();
    const history = useHistory();
    const { action, websiteId, pageNo }: any = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [websiteSearch, setWebsiteSearch] = useState<string>();
    const [showFrequencyInfo, setShowFrequencyInfo] = useState<number | undefined>();
    const [data, setData] = useState<Array<TestScheduleModel>>();
    const [dataError, setDataError] = useState<{ error: string }>();
    const limit = 10;
    let pgNo = 1;
    if (action != 'edit' && action != 'add') {
        pgNo = parseInt(action);
    }
    if (pgNo < 1 || Number.isNaN(pgNo)) {
        pgNo = 1
    }

    const fetchData = (clearSearch?: boolean) => {
        setIsLoading(true);
        const where: any = { status: 'ACTIVE' };
        if (websiteId) {
            where.websiteId = websiteId;
        }
        const f: any = { where, include: ['website'], limit: limit + 1, skip: (pgNo - 1) * limit };
        const search = !clearSearch && websiteSearch && websiteSearch.trim() != '' ? websiteSearch : undefined;
        getTestSchedulesWithSearch(f, search).then((result) => setData(result.data))
            .catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }))
            .finally(() => setIsLoading(false));
    }

    useEffect(fetchData, [pgNo, websiteId]);

    if (dataError || data == null) {
        return (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    {dataError ? (
                        <div>
                            <br />
                            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                                <div className='alert-text font-weight-bold'>{dataError.error}</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
    const title = `Manage Test ${websiteId && data.length > 0 ? `for ${cleanString(data[0].website.name)}` : ''}`;
    let defaultBreadcrumbs: BreadcrumbData[];
    //@ts-ignore
    if (history.location.state && history.location.state.previousBreadcrumbs) {
        //@ts-ignore
        defaultBreadcrumbs = history.location.state.previousBreadcrumbs.concat([{ text: title }]);
    }
    else {
        defaultBreadcrumbs = [
            {
                href: `/v2/activities/testmgmt/schedules-by-website`,
                text: 'Manage Tests'
            },
            {
                text: title
            }
        ];
    }

    const onDelete = (id: number) => {
        if (!window.confirm('Are you sure want to delete?')) {
            return;
        }
        deleteTestSchedule(id).then(() => {
            setData(data?.filter(x => x.id != id));
        });
    }

    const onRunTest = (row: TestScheduleModel) => {
        setIsLoading(true);
        runTest(row.name, row.baseUrl, row.testSettings, row.website.id, row.id).then(({ data: { id } }) => {
            setIsLoading(false);
            history.push(`/v2/core/test/${id}/overview`,
                { previousBreadcrumbs: defaultBreadcrumbs.map((x, i) => i == defaultBreadcrumbs.length - 1 ? ({ ...x, href: history.location.pathname }) : x) });
        }).catch(e => {
            setIsLoading(false);
            const errorData = e?.response?.data;
            const lbErrorCode = errorData?.error?.code;
            const errorMsg = lbErrorCode == "ER_UNREACHABLE_URL" ? errorData?.error?.message : 'Server Error! Cannot run the test';
            setDataError({ error: errorMsg });
        }).finally(() => setIsLoading(false));
    }
    const manageUrlPrefx = websiteId ? `/v2/activities/testmgmt/schedules-by-website/${websiteId}` : `/v2/activities/testmgmt/schedules`;
    const onSearchSubmit = (e: any, clearSearch?: boolean) => { history.replace(`${manageUrlPrefx}`); fetchData(clearSearch); e.preventDefault(); };

    return (
        <>
            <Breadcrumbs data={defaultBreadcrumbs} />
            <div className="site-head">
                <h1 className="main-title">{title}</h1>
                <div className="site-head-right">
                    <form className="search-web-form" onSubmit={onSearchSubmit}>
                        <span className="icon"><i className="fa fa-search" aria-hidden="true" /></span>
                        <input type="search" id="search" placeholder="Search Website/Orgnization" value={websiteSearch} onChange={(e) => setWebsiteSearch(e.target.value)} onBlur={onSearchSubmit} />
                        {websiteSearch && websiteSearch.trim().length > 0 ? <span className="searchBox-clear js-clearSearchBox" onClick={(e) => {setWebsiteSearch(''); onSearchSubmit(e, true);}}>clear search</span> : null}
                    </form>
                    <Link to={`${manageUrlPrefx}/add`} className="btn btn-red" data-target="#details-popup">
                        <em className="fa fa-plus" aria-hidden="true"></em> Add Test
                    </Link>
                </div>
            </div>
            <div className="project-list">
                {isLoading ?
                    <div>
                        <br />
                        <strong>
                            <span>Loading... Please wait... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </strong>
                    </div> :
                    <table className="weblist-table add-manage-test">
                        <thead>
                            <tr>
                                <th scope="col">Website</th>
                                <th scope="col">Test Name</th>
                                <th scope="col">WCAG Version</th>
                                <th scope="col">WCAG Level</th>
                                <th scope="col">Frequency</th>
                                <th scope="col" className="action">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.length > 0 ? null : 'No data to display'}
                            {data?.slice(0, limit).map((row, index) => (
                                <tr>
                                    <td className="website-name add-test">
                                        <strong>{cleanString(row.website?.name) || row.baseUrl}</strong> <br />
                                        <small>{row.website?.url}</small>
                                    </td>
                                    <td><strong>{row.name}</strong></td>
                                    <td>{row.testSettings.wcagVersion}</td>
                                    <td>{row.testSettings.wcagLevel}</td>
                                    <td>
                                        {row.cron ?
                                            <>
                                                <strong>{getFrequencyText(row.cron)} </strong>
                                                <div className="view-note">
                                                    <a href="#" onClick={() => setShowFrequencyInfo(index)} className="show-error-note red" title="Information"><em className="fa fa-exclamation-circle" aria-hidden="true"></em></a>
                                                    <div className={`error-notbox ${showFrequencyInfo == index ? 'show' : ''}`}>
                                                        <p>Tests will be conducted
                                                            <strong> {cronstrue.toString(row.cron).toLowerCase()}</strong>
                                                        </p>
                                                        <a href="#" onClick={() => setShowFrequencyInfo(undefined)} className="alert-close"><em className="fa fa-times" aria-hidden="true"></em></a>
                                                    </div>
                                                </div>
                                            </> : null}
                                    </td>
                                    <td>
                                    <ViewIconLink href='#' className='edit' title="View Test" onClick={e => {history.push(`/v2/activities/testmgmt/schedulereport/${row.id}`,
                                                { previousBreadcrumbs: defaultBreadcrumbs.map((x, i) => i == defaultBreadcrumbs.length - 1 ? ({ ...x, href: history.location.pathname }) : x) });e.preventDefault();}} />
                                    <RunIconLink href='#' className='edit' title="Run Test" onClick={e => {onRunTest(row); e.preventDefault();}} />
                                    <EditIconLink href='#' className='edit' title="Edit Test" onClick={(e) => {history.push(`${manageUrlPrefx}/edit/${row.id}`);e.preventDefault();}} data-target="#details-popup" />
                                    {row.name == STANDARD_TEST_NAME ? null : <DeleteIconLink href='#' className='edit' title="Delete Test" onClick={(e) => {onDelete(row.id);e.preventDefault();}} />}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}
            </div>
            <nav aria-label="Page navigation" className="pagination-wrap">
                {data ?
                    <Pagination
                        currentPage={pgNo}
                        totalCount={data.length}
                        pageSize={limit}
                        infinite={true}
                        onPageChange={(page: number) => history.push(`${manageUrlPrefx}/${page}`)}
                    /> : null}
            </nav>
            <AddEditTestSchedule onSave={fetchData} />
        </>
    )
}

export { ManageTestSchedules }
