import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../../setup';
import { FORM_STATUSES, getUserLabel } from '../../modules/utils';
import { DetailsPopup } from '../websites/DetailsPopup';
import { useFormik } from 'formik';
import { ErrorMsg } from '../layout/MasterLayout';
import _ from 'lodash';
import { createUser, editUser, getUser } from '../../modules/auth/redux/AuthCRUD';
import { UserDbModel, UserModel } from '../../modules/auth/models/UserModel';
import { Settings } from '../../utils/config';
import { OrganizationModel } from '../../modules/auth/models/OrganizationModel';
import { viewDepartment } from '../../modules/auth/redux/OrganizationCRUD';
import { cleanString } from '../utils';

const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email is required'),
    departmentIds: Yup.array()
        .test("orgTest", "Select an organization", function (value) {
            return this.parent.role == 'SUPERADMIN' || (value != null && value.length > 0);
        }),
    role: Yup.string()
        .oneOf(['TESTER', 'ADMIN', 'SUPERADMIN'])
        .required('Select a role'),
    status: Yup.string().oneOf(['ACTIVE', 'INACTIVE']).required('Select a status'),
});

const AddEditUser: React.FC<{ onSave: any, manageUrlPrefix: string }> = ({ onSave, manageUrlPrefix }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const { action, role, userId, orgId }: any = useParams();
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [orgData, setOrgData] = useState<Array<OrganizationModel>>();
    const [data, setData] = useState<UserDbModel>();
    const [dataError, setDataError] = useState<DataError>();
    const roleTitle = getUserLabel(role);
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
    const initialValues = { name: '', email: '', role: role?.toUpperCase(), departmentIds: undefined, status: 'ACTIVE' };
    let id: string | undefined = undefined;
    if (action == 'edit' && userId) {
        id = userId;
    }
    const isActive = action == 'add' || action == 'edit';
    const isSuperAdmin = user.gamyata && user.gamyata.role == 'SUPERADMIN';
    const isAdmin = user.gamyata && user.gamyata.role == 'ADMIN';

    const formik = useFormik<any>({
        initialValues,
        validationSchema: schema,
        onSubmit: (values, { setStatus }) => {
            setLoading(true);
            const parichay = data?.parichay || {};
            parichay.fullName = values.name;
            parichay.givenName = values.name;

            const promise = id ? editUser(id, parichay, values.email, values.role, values.departmentIds, values.status)
                : createUser(values.name, values.email, values.role, values.departmentIds);

            promise.then(({ data: { id } }) => {
                setLoading(false);
                setShowPopup(true);
                setStatus({ state: FORM_STATUSES.SUCCESS, response: { id } });
            }).catch((e) => {
                setLoading(false);
                const errorData = e?.response?.data;
                const lbErrorCode = errorData?.error?.code;
                const errorMsg = lbErrorCode == "ER_DUP_ENTRY" ? 'Duplicate User! Cannot save user!' : 'Server Error! Cannot save user!';
                setStatus({ state: FORM_STATUSES.FAILURE, response: { error: errorMsg } });
            });
        },
    });

    useEffect(() => {
        formik.resetForm();
        if ((isSuperAdmin || isAdmin) && role?.toUpperCase() != 'SUPERADMIN') {
            viewDepartment().then((result) => setOrgData(result.data)).catch(e => setDataError({error: "Server Error! Cannot fetch org data!"}));    
        }
        if (id) {
            getUser(id).then((result: any) => {
                const departments = result.data.departments || [];
                const departmentIds = departments.map((d: any) => d.id);
                formik.setFieldValue('email', result.data.email);
                formik.setFieldValue('role', result.data.role);
                formik.setFieldValue('departmentIds', departmentIds);
                formik.setFieldValue('status', result.data.status);
                if (result.data.parichay) {
                    formik.setFieldValue('name', result.data.parichay.fullName)
                }
                setData(result.data);
            }).catch(e => {
                console.error(e);
                setDataError({ error: "Server Error! Cannot fetch user data!" })
            });
        }
    }, [isSuperAdmin, id, role]);

    const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile, shallowEqual)
    const closePopup = () => history.push(`${manageUrlPrefix}/users/${role}`);

    if (dataError) {
        return (
            <DetailsPopup key={'is: ' + isActive} title={`${_.startCase(action)} ${roleTitle}`} isActive={isActive} onCloseBtnClick={closePopup}>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    <div>
                        <br />
                        <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                            <div className='alert-text font-weight-bold'>{dataError.error}</div>
                        </div>
                    </div>
                </div>
            </div>
            </DetailsPopup>
        );
    }

    return (
        <>
            <DetailsPopup key={'is: ' + isActive} title={`${_.startCase(action)} ${roleTitle}`} isActive={isActive} onCloseBtnClick={closePopup}
                onSave={onSave} closePopup={closePopup} showPopup={showPopup} setShowPopup={setShowPopup} popupMessage={`${roleTitle} is ${id ? 'updated' : 'created'} successfully`}>
                <div className="row">
                    {formik.status && formik.status.state == FORM_STATUSES.FAILURE ? (
                        <div className='alert-danger' style={{ padding: 5 }}>
                            <div className='alert-text font-weight-bold'>{formik.status.response?.error}</div>
                        </div>
                    ) : null}
                    <div className="col-md-12">
                        <form onSubmit={formik.handleSubmit} noValidate className='test-form'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <label htmlFor='test-name'>Name</label>
                                    <input
                                        id='test-name'
                                        type='text'
                                        placeholder='Enter name'
                                        {...formik.getFieldProps('name')}
                                    />
                                    {formik.touched.name && formik.errors.name && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.name}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <label htmlFor='test-email'>Email</label>
                                    <input
                                        id='test-email'
                                        type='text'
                                        placeholder='Enter email'
                                        {...formik.getFieldProps('email')}
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.email}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                            </div>

                        {data?.managedByGigw ? 
                        <>
                        <div className='row'>
                        <div className='col-md-12' style={{paddingTop: 20}}>
                            <span>
                            The following fields cannot be modified here. Please email your request to s3waas.support@nic.in to update it
                            </span>
                        </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <label htmlFor='test-email'>Role</label>
                                {getUserLabel(data.role)}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <label htmlFor='test-email'>Organization</label>
                                {data.departments?.map((d: any) => d.name).join(', ')}
                            </div>
                        </div>
                        </>
                        : <>
                        {role?.toUpperCase() != 'SUPERADMIN' ? 
                        <div className='row'>
                                <div className='col-md-12'>
                                    <label htmlFor='test-email'>Role</label>
                                    <select
                                        id='s_role' 
                                        className='form-select form-select-solid form-select-lg'
                                        {...formik.getFieldProps('role')}
                                        >
                                            <option value='TESTER'>{Settings.TesterLabel}</option>
                                            <option value='ADMIN'>{Settings.AdminLabel}</option>
                                        </select>
                                    {formik.touched.role && formik.errors.role && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.role}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                            </div> : null}

                            {(isSuperAdmin || isAdmin) && role?.toUpperCase() != 'SUPERADMIN' ? 
                            <div className='row'>
                                <div className='col-md-12'>
                                    <label htmlFor='test-email'>Organization</label>
                                    <select 
                                        id='s_org'
                                        className='form-select form-select-solid form-select-lg'
                                        {...formik.getFieldProps('departmentIds')}
                                        multiple
                                        >
                                        {orgData?.map(org => (
                                            <option value={org.id}>{cleanString(org.name)}</option>
                                        ))}
                                        </select>
                                    {formik.touched.departmentIds && formik.errors.departmentIds && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.departmentIds}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                            </div> : null}
                        </>}

                            <div className='row'>
                                <div className='col-md-12 testingmode1'>
                                    <button onClick={closePopup} className='btn cancel-form' disabled={loading}>
                                        Cancel
                                    </button>
                                    <button type='submit' className='btn btn-red' disabled={loading}>
                                        {!loading && 'Save'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </DetailsPopup>
        </>
    )
}

export { AddEditUser }
