import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../../setup';
import Pagination from '../layout/Pagination';
import { deleteDepartment, viewDepartment } from '../../modules/auth/redux/OrganizationCRUD';
import { OrganizationModel } from '../../modules/auth/models/OrganizationModel';
import { AddEditOrganization } from './AddEditOrganization';
import { UserModel } from '../../modules/auth/models/UserModel';
import { cleanString } from '../utils';

const ManageOrganization: React.FC = () => {
    const { pathname } = useLocation();
    const history = useHistory();
    const { action, orgId }: any = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState<string>('');
    const [data, setData] = useState<Array<OrganizationModel>>();
    const [filteredData, setFilteredData] = useState<Array<OrganizationModel>>();
    const [dataError, setDataError] = useState<{ error: string }>();
    const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile, shallowEqual)
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const isSuperAdmin = user.gamyata && user.gamyata.role == 'SUPERADMIN';

    const pageSize = 10;
    let pgNo = 1;
    if (action == 'manage') {
        pgNo = parseInt(orgId);
    }
    if (pgNo < 1 || Number.isNaN(pgNo)) {
        pgNo = 1
    }

    const start = (pgNo - 1) * pageSize;

    const fetchData = () => {
        setIsLoading(true);
        //const f: any = { limit: limit + 1, skip: (pgNo - 1) * limit };
        viewDepartment().then((result) => setData(result.data))
            .catch(e => setDataError({ error: "Server Error! Cannot fetch data!" }))
            .finally(() => setIsLoading(false));
    }

    useEffect(fetchData, []);

    useEffect(() => {
        const newFilteredData = data?.filter(x => x.name.toLowerCase().includes(search.toLowerCase()));
        setFilteredData(newFilteredData);
    }, [search, data]);

    if (isLoading) {
        return (
            <div>
                <br />
                <strong>
                    <span>Loading... Please wait... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </strong>
            </div>
        );
    }

    if (dataError || data == null) {
        return (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    {dataError ? (
                        <div>
                            <br />
                            <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                                <div className='alert-text font-weight-bold'>{dataError.error}</div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <span>Loading... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    const onDelete = (id: number) => {
        if (!window.confirm('Are you sure want to delete?')) {
            return;
        }
        deleteDepartment(id).then(() => {
            setData(data?.filter(x => x.id != id));
        });
    }

    return (
        <>
            <div className="bredcrumb-cnntr">
                <ul>
                    <li><Link to={`/v2/dashboard`}>Home</Link></li>
                    <li>Manage Organization</li>
                </ul>
            </div>
            <div className="site-head">
                <h1 className="main-title">Manage Organization</h1>
                <div className="site-head-right">
                    <div className="search-web-form">
                        <span className="icon"><i className="fa fa-search" aria-hidden="true" /></span>
                        <input type="search" id="search" placeholder="Search by email or name" value={search} onChange={(e) => setSearch(e.target.value)} />
                        {search?.trim().length > 0 ? <span className="searchBox-clear js-clearSearchBox" onClick={() => setSearch('')}>clear search</span> : null}
                    </div>
                    <Link to={`/v2/core/org/add`} className="btn btn-red" data-target="#details-popup">
                        <em className="fa fa-plus" aria-hidden="true"></em> Add Organization
                    </Link>
                </div>
            </div>
            <div className="project-list">
                <table className="weblist-table add-manage-test">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Average Score</th>
                            <th scope="col">Website Count</th>
                            <th scope="col" >Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData && filteredData.length > 0 ? null : 'No data to display'}
                        {filteredData?.slice(start, start + pageSize).map(row => (
                            <tr>
                                <td>
                                    {cleanString(row.name)} <br />
                                </td>
                                <td>{Math.round(row.avgScore)}</td>
                                <td>{row.websiteCnt}</td>
                                <td>
                                    <Link className='btn'
                                        to={`/v2/core/org/${row.id}/users/admin`}>
                                        Nodal Officers
                                    </Link>
                                    <Link className='btn'
                                        to={`/v2/core/org/${row.id}/users/tester`}>
                                        QA Testers
                                    </Link>
                                    {isSuperAdmin ? (<>
                                    <Link className='edit' data-target="#details-popup"
                                        to={`/v2/core/org/edit/${row.id}`}>
                                        <em className="fa fa-pencil" aria-hidden="true"></em>
                                    </Link> 
                                    <Link className='edit'
                                        to={`#`}
                                        onClick={() => {
                                            onDelete(row.id);
                                        }}>
                                        <em className="fa fa-trash-o" aria-hidden="true"></em>
                                    </Link>
                                    </>) : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <nav aria-label="Page navigation" className="pagination-wrap">
                {data ?
                    <Pagination
                        currentPage={pgNo}
                        totalCount={filteredData ? filteredData.length : 0}
                        pageSize={pageSize}
                        infinite={false}
                        onPageChange={(page: number) => history.push(`/v2/core/org/manage/${page}`)}
                    /> : null}
            </nav>
            <AddEditOrganization onSave={fetchData} />
        </>
    )
}

export { ManageOrganization }
