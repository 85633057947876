import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch, connect, ConnectedProps } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { RootState } from '../../../setup';
//import * as auth from '../../modules/auth/redux/AuthRedux';
import { UserDbModel, UserModel } from '../../modules/auth/models/UserModel';
import { getUserMyself } from '../../modules/auth/redux/AuthCRUD';
import { actions } from '../../modules/auth/redux/AuthRedux';
import { cleanString, getUserRoleLabel } from '../utils';
import { viewWebsite } from '../../modules/auth/redux/OrganizationCRUD';
import { WebsiteModel } from '../../modules/auth/models/OrganizationModel';

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const ViewProfile: React.FC<PropsFromRedux> = (props) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [tab, setTab] = useState(0);
    const [data, setData] = useState<UserDbModel>();
    const [websites, setWebsites] = useState<WebsiteModel[]>();
    const [dataError, setDataError] = useState<DataError>();
    const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile, shallowEqual)
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const name = user.gamyata?.givenName ? user.gamyata.givenName : user.fullName;
    const isSuperAdmin = user.gamyata && user.gamyata.role == 'SUPERADMIN';
    const isMocked = user.gamyata?.originalRole != null;
    useEffect(() => {
        setLoading(true);
        const promises = [getUserMyself()];
        if (!isSuperAdmin) {
            promises.push(viewWebsite(undefined, true));
        }
        Promise.all(promises).then(([userResults, websitesResults]) => {
            setData(userResults.data);
            if (!isSuperAdmin) {
                setWebsites(websitesResults.data);
            }
        }).catch(e => {
            console.error(e);
            setDataError({ error: "Server Error! Cannot fetch user data!" })
        }).finally(() => setLoading(false));
    }, [user.gamyata?.role]);

    const changeRole = (role: string) => {
        if (!user.gamyata) {
            return;
        }
        if (!user.gamyata.originalRole) {
            user.gamyata.originalRole = user.gamyata.role;
        }
        user.gamyata.role = role;
        dispatch(props.login({ ...user }));
        history.go(0);
    }

    if (loading || !data) {
        return (
            <div>
                <br />
                <strong>
                    <span>Loading... Please wait... </span> <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </strong>
            </div>
        );
    }


    if (dataError) {
        return (
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0'
                >
                    <div>
                        <br />
                        <div className='mb-lg-15 alert alert-danger d-flex flex-column-fluid flex-center'>
                            <div className='alert-text font-weight-bold'>{dataError.error}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="bredcrumb-cnntr">
                <ul>
                    <li><Link to={`/v2/dashboard`}>Home</Link></li>
                    <li>Profile</li>
                </ul>
            </div>
            <div className="site-head">
                <h1 className="main-title">Profile</h1>
            </div>
            <div className="profile-outer">
                <div className="basic-profile">
                    <div className="avatar-preview">
                        <div className="avtar-user"><strong>{name.charAt(0).toUpperCase()}</strong></div>
                        <div id="imagePreview"></div>
                    </div>

                    <div className="profile-info">
                        <p className="h2">{name}</p>
                        <p>{getUserRoleLabel(user.gamyata.role)}</p>
                    </div>
                </div>
                <div className="update-profile-box">
                    <div className="tabs">
                        <ul id="tabs-nav">
                            <li className={tab == 0 ? "active" : ""}><a href="#" onClick={() => setTab(0)}>Basic Info</a></li>
                            {isSuperAdmin ? null : <li className={tab == 1 ? "active" : ""}><a href="#" onClick={() => setTab(1)}>Assigned Sites</a></li>}
                        </ul>
                        <div id="tabs-content">
                            {tab == 0 ?
                                <div id="basic_info" className="tab-content">
                                    <div className="col-md-6">
                                        <form className="test-form">
                                            <div className="basic_info_form">
                                                <label htmlFor="edit-email">Email ID : </label>
                                                <div className="profile-data">
                                                    <span id="old_email">{user.email}</span>
                                                </div>

                                            </div>
                                            <div className="basic_info_form">
                                                <label htmlFor="mobile_number">Mobile Number : </label>
                                                <div className="profile-data">
                                                    <span id="old_mobile_number">{user.mobileNo}</span>
                                                </div>

                                            </div>
                                            <div className="basic_info_form">
                                                <label htmlFor="department">Department : </label>
                                                <div className="profile-data">
                                                    <span>{user.departmentName}</span>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="col-md-6"></div>
                                    </div>
                                </div> : null}
                            {tab == 1 ? <div id="assigned_sites" className="tab-content">
                                <div className="project-list">
                                    <div className="table-responsive">
                                        <table className="weblist-table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" colSpan={3}>Website URL</th>
                                                    <th scope="col" colSpan={2} className="text-right">Website Name</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {websites?.map(w =>
                                                    <tr>
                                                        <td colSpan={3}><strong><a href="#">{w.url}</a></strong></td>
                                                        <td colSpan={2} className="text-right">{cleanString(w.name)}</td>

                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> : null}
                        </div>
                    </div>

                </div>

            </div>


        </>
    )
}
const connectedViewProfile = connector(ViewProfile);
export { connectedViewProfile as ViewProfile }
