import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { RootState } from '../../../setup';
import { FORM_STATUSES } from '../../modules/utils';
import { DetailsPopup } from '../websites/DetailsPopup';
import { useFormik } from 'formik';
import { ErrorMsg } from '../layout/MasterLayout';
import { createApiKey } from '../../modules/auth/redux/AuthCRUD';

const schema = Yup.object().shape({
    serviceName: Yup.string().required('Service Name is required'),
});

const AddApiKey: React.FC<{ onSave: any }> = ({ onSave }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    const { action }: any = useParams();
    const [showPopup, setShowPopup] = useState(false);
    const initialValues = { serviceName: '' };
    const isActive = action == 'add';

    const formik = useFormik<any>({
        initialValues,
        validationSchema: schema,
        onSubmit: (values, { setStatus }) => {
            setLoading(true);
            createApiKey(values.serviceName).then(({ data: { id } }) => {
                setLoading(false);
                setShowPopup(true);
                setStatus({ state: FORM_STATUSES.SUCCESS, response: { id } });
            }).catch((e) => {
                setLoading(false);
                const errorMsg = 'Server Error! Cannot create api key!';
                setStatus({ state: FORM_STATUSES.FAILURE, response: { error: errorMsg } });
            });
        },
    });

    useEffect(() => {
        formik.resetForm();
    }, [pathname]);

    const isMobile = useSelector<RootState>(({ auth }) => auth.isMobile, shallowEqual)
    const closePopup = () => history.push(`/v2/core/apikeys/view`);

    return (
        <>
            <DetailsPopup key={'is: ' + isActive} title={`Add API Key`} isActive={isActive} onCloseBtnClick={closePopup}
                 onSave={onSave} closePopup={closePopup} showPopup={showPopup} setShowPopup={setShowPopup} popupMessage={`API Key is created successfully`}>
                <div className="row">
                    {formik.status && formik.status.state == FORM_STATUSES.FAILURE ? (
                        <div className='alert-danger' style={{ padding: 5 }}>
                            <div className='alert-text font-weight-bold'>{formik.status.response?.error}</div>
                        </div>
                    ) : null}
                    <div className="col-md-12">
                        <form onSubmit={formik.handleSubmit} noValidate className='test-form'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <label htmlFor='test-name'>Service Name</label>
                                    <input
                                        id='test-name'
                                        type='text'
                                        placeholder='Enter name'
                                        {...formik.getFieldProps('serviceName')}
                                    />
                                    {formik.touched.serviceName && formik.errors.serviceName && (
                                        <ErrorMsg>
                                            <div className='fv-help-block'>{formik.errors.serviceName}</div>
                                        </ErrorMsg>
                                    )}
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12 testingmode1'>
                                    <button onClick={closePopup} className='btn cancel-form' disabled={loading}>
                                        Cancel
                                    </button>
                                    <button type='submit' className='btn btn-red' disabled={loading}>
                                        {!loading && 'Save'}
                                        {loading && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </DetailsPopup>
        </>
    )
}

export { AddApiKey }
